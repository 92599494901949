import { forwardRef } from 'react';

import { Icon, IconProps } from '@/component-library/Icon';

const KSM = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>KSM</title>
    <g clipPath='url(#clip0_1308_233)'>
      <path
        d='M16 31.5C7.43958 31.5 0.5 24.5605 0.5 16C0.5 7.43958 7.43958 0.5 16 0.5C24.5605 0.5 31.5 7.43958 31.5 16C31.5 24.5605 24.5605 31.5 16 31.5Z'
        fill='black'
        stroke='white'
      />
      <path
        d='M27.1345 9.22903C26.7563 8.93084 26.3054 8.52357 25.4836 8.42175C24.7126 8.31993 23.9272 8.8363 23.3963 9.17812C22.8654 9.51993 21.8617 10.5235 21.4472 10.829C21.0326 11.1344 19.9708 11.4181 18.2617 12.4435C16.5526 13.469 9.84718 17.7745 9.84718 17.7745L11.5926 17.7963L3.81081 21.8035H4.58899L3.46899 22.6545C3.46899 22.6545 4.45809 22.9163 5.28718 22.3926V22.6326C5.28718 22.6326 14.5526 18.9817 16.3417 19.9272L15.2508 20.2472C15.3454 20.2472 17.1054 20.3635 17.1054 20.3635C17.1481 20.728 17.2706 21.0786 17.4642 21.3903C17.6577 21.702 17.9176 21.9673 18.2254 22.1672C19.2872 22.8654 19.309 23.2508 19.309 23.2508C19.309 23.2508 18.7563 23.4763 18.7563 23.7599C18.7563 23.7599 19.5708 23.5126 20.3272 23.5345C20.8072 23.5527 21.2834 23.6284 21.7454 23.7599C21.7454 23.7599 21.6872 23.4545 20.9526 23.2508C20.2181 23.0472 19.4908 22.2472 19.1345 21.8108C18.9162 21.5331 18.7739 21.2033 18.7216 20.8539C18.6694 20.5045 18.7089 20.1476 18.8363 19.8181C19.0908 19.1563 19.9781 18.7926 21.8108 17.8472C23.9708 16.7272 24.4654 15.8981 24.7708 15.2508C25.0763 14.6035 25.5272 13.3163 25.7817 12.7126C26.1017 11.9344 26.4945 11.5199 26.8217 11.2726C27.149 11.0254 28.6036 10.4799 28.6036 10.4799C28.6036 10.4799 27.4908 9.51266 27.1345 9.22903Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_1308_233'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </Icon>
));

KSM.displayName = 'KSM';

export { KSM };
