import { forwardRef } from 'react';

import { Icon, IconProps } from '@/component-library/Icon';

const INTR = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>INTR</title>
    <g clipPath='url(#clip0_35_8)'>
      <circle cx='12' cy='12' r='11.5' fill='#2759B6' stroke='#001D7A' />
      <g clipPath='url(#clip1_35_8)'>
        <path
          d='M18.7539 18H9.26488C9.2197 17.9999 9.17543 17.9874 9.13694 17.9639L5.70635 15.866C5.66123 15.8384 5.62644 15.7969 5.60723 15.748C5.58803 15.699 5.58546 15.6452 5.59993 15.5946C5.61439 15.5441 5.6451 15.4996 5.68739 15.4679C5.72969 15.4362 5.78126 15.419 5.8343 15.419H15.3246C15.3699 15.4191 15.4144 15.4316 15.453 15.455L18.8818 17.5516C18.9276 17.579 18.963 17.6205 18.9826 17.6697C19.0023 17.7189 19.005 17.7732 18.9905 17.8241C18.9759 17.8751 18.9449 17.9199 18.9022 17.9517C18.8594 17.9834 18.8073 18.0004 18.7539 18ZM9.33241 17.516H17.8947L15.2557 15.9025H6.69306L9.33241 17.516ZM18.163 9.57972H8.67268C8.62749 9.57964 8.5832 9.56716 8.54474 9.54364L5.11549 7.44704C5.07034 7.4194 5.03552 7.37793 5.01633 7.32892C4.99713 7.27991 4.9946 7.22603 5.00912 7.17547C5.02364 7.12491 5.05442 7.08042 5.09679 7.04875C5.13916 7.01708 5.1908 6.99997 5.24388 7H14.7342C14.7795 6.99999 14.824 7.01249 14.8626 7.03608L18.2914 9.13268C18.3365 9.1603 18.3713 9.20173 18.3905 9.2507C18.4097 9.29967 18.4123 9.3535 18.3978 9.40404C18.3833 9.45458 18.3526 9.49907 18.3103 9.53077C18.2681 9.56248 18.2165 9.57966 18.1634 9.57972H18.163ZM8.74199 9.09572H17.306L14.6649 7.484H6.10263L8.74199 9.09572ZM13.3077 11.451C13.0292 11.179 12.6535 11.0269 12.2623 11.0278H12.2468C11.8504 11.0297 11.471 11.1875 11.1921 11.4664C10.9132 11.7454 10.7577 12.1226 10.7596 12.5152C10.7615 12.9077 10.9209 13.2835 11.2025 13.5597C11.4842 13.8359 11.8651 13.99 12.2614 13.9881H12.2765C12.5721 13.9851 12.8601 13.8954 13.1042 13.7304C13.3483 13.5653 13.5374 13.3323 13.6478 13.0607C13.7582 12.7892 13.7848 12.4913 13.7242 12.2048C13.6637 11.9183 13.5187 11.656 13.3077 11.451Z'
          fill='white'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_35_8'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
      <clipPath id='clip1_35_8'>
        <rect width='14' height='11' fill='white' transform='translate(5 7)' />
      </clipPath>
    </defs>
  </Icon>
));

INTR.displayName = 'INTR';

export { INTR };
