import { forwardRef } from 'react';

import { Icon, IconProps } from '@/component-library/Icon';

const WETH = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>WETH</title>
    <g clipPath='url(#weth-a)'>
      <path
        fill='#000'
        d='M6.728 23.803c6.033 0 6.033-.09 7.056-.393 1.336-.397-3.775-3.935-7.056-3.935s-5.941.97-5.941 2.164c0 1.195 2.66 2.164 5.941 2.164Z'
      />
      <path
        fill='#000'
        d='m20.426 5.402.278.299-.243.328c-7.867 10.637-12.4 15.453-14.323 14.807-1.17-.393-2.345-1.576-3.52-3.295a24.253 24.253 0 0 1-1.69-2.895 2.55 2.55 0 0 1-.231-.67L.69 13.95a2.297 2.297 0 0 0-.038-.177 10.736 10.736 0 0 1-.358-2.757c0-5.92 4.8-10.72 10.721-10.72 1.855 0 3.643.471 5.227 1.357l.041.026c1.297.903 2.677 2.144 4.142 3.723ZM15.742 2.5a9.691 9.691 0 0 0-4.726-1.221c-5.378 0-9.737 4.36-9.737 9.737 0 .855.11 1.695.325 2.505.02.08.03.125.053.241l.005.024c.028.144.058.236.154.437a23.283 23.283 0 0 0 1.614 2.763c1.061 1.553 2.114 2.613 3.022 2.918 1.21.407 5.77-4.42 12.974-14.13-1.308-1.381-2.537-2.472-3.684-3.274Z'
      />
      <path
        fill='#EC1C79'
        d='M15.742 2.5a9.69 9.69 0 0 0-4.726-1.221c-5.378 0-9.737 4.36-9.737 9.737 0 .855.11 1.695.325 2.505.02.08.03.125.053.241l.005.024c.028.144.058.236.154.437a23.307 23.307 0 0 0 1.614 2.763C4.49 18.54 5.544 19.6 6.452 19.904c1.21.407 5.77-4.42 12.974-14.13-1.308-1.381-2.537-2.472-3.684-3.274Z'
      />
      <path
        fill='#000'
        d='M12.984 23.705c-5.922 0-10.722-4.8-10.722-10.721 0-5.922 4.8-10.722 10.722-10.722 5.92 0 10.721 4.8 10.721 10.722 0 5.92-4.8 10.72-10.721 10.72Zm0-.984c5.378 0 9.737-4.36 9.737-9.737 0-5.378-4.36-9.738-9.737-9.738-5.378 0-9.738 4.36-9.738 9.738 0 5.378 4.36 9.737 9.738 9.737Z'
      />
      <path
        fill='#fff'
        d='M12.984 22.721c5.378 0 9.737-4.36 9.737-9.737 0-5.378-4.36-9.738-9.737-9.738-5.378 0-9.738 4.36-9.738 9.738 0 5.378 4.36 9.737 9.738 9.737Z'
      />
      <path
        fill='#000'
        fillRule='evenodd'
        d='m5.7 14.978-1.29-3.883h1.13l.672 2.23.743-2.241h.92l.744 2.242.672-2.23h1.107l-1.289 3.882h-.93l-.778-2.22-.77 2.22h-.932Zm5.122-.027v-3.856h3.101v.909H11.88v.584h1.85v.843h-1.85v.61h2.07v.91h-3.128Zm4.616 0v-2.92H14.28v-.936h3.382v.936h-1.157v2.92h-1.068Zm2.693 0v-3.856H19.2v1.443h1.372v-1.443h1.068v3.856h-1.068v-1.465H19.2v1.465h-1.069Z'
        clipRule='evenodd'
      />
      <path
        fill='#000'
        d='M.934 9.935a.55.55 0 0 1 .493-.985L3 9.737a.55.55 0 1 1-.492.985L.934 9.935ZM.753 12.545a.55.55 0 0 1 .461-1l1.574.726a.55.55 0 0 1-.461 1l-1.574-.726Z'
      />
    </g>
    <defs>
      <clipPath id='weth-a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </Icon>
));

WETH.displayName = 'WETH';

export { WETH };
