import { forwardRef } from 'react';

import { Icon, IconProps } from '@/component-library/Icon';

const HDX = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref} viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>HDX</title>
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_762_16392)'>
        <mask
          id='mask0_762_16392'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='48'
          height='48'
        >
          <path
            d='M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_762_16392)'>
          <path
            d='M0 14C0 6.26801 6.26801 0 14 0H34C41.732 0 48 6.26801 48 14V34C48 41.732 41.732 48 34 48H14C6.26801 48 0 41.732 0 34V14Z'
            fill='#F6297C'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.75 24.0007L24.0068 4.75L43.25 24.0057L24.0068 43.25L4.75 24.0007ZM35.3779 18.2227C34.5779 19.1167 32.8333 21.3678 32.8333 24.0007C32.8333 26.6336 34.5779 28.8847 35.3779 29.7786C30.2384 29.5066 26.129 24.0007 26.129 24.0007C26.129 24.0007 30.2384 18.4947 35.3779 18.2227ZM12.6499 18.2227C17.7894 18.4947 21.8988 24.0007 21.8988 24.0007C21.8988 24.0007 17.7894 29.5066 12.6499 29.7786C13.4499 28.8847 15.1944 26.6336 15.1944 24.0007C15.1944 21.3678 13.4499 19.1167 12.6499 18.2227ZM11.8356 18.2568C11.0224 19.172 9.32048 21.4001 9.32048 24.0007C9.32048 26.6114 11.0358 28.8469 11.8451 29.7553L16.008 33.9216L16.0276 33.9024C17.6942 32.2727 20.4647 30.1308 23.6231 29.9677C22.8279 30.8542 21.0744 33.1083 21.0744 35.7461C21.0744 38.3839 22.8279 40.638 23.6231 41.5245L24.0068 41.9266L24.3904 41.5245C25.1856 40.638 26.9391 38.3839 26.9391 35.7461C26.9391 33.1083 25.1856 30.8542 24.3904 29.9677C27.5612 30.1315 30.341 32.2895 32.0055 33.9216L36.2666 29.657L36.2765 29.6457C37.1191 28.6775 38.7073 26.5129 38.7073 24.0007C38.7073 21.4787 37.1066 19.3069 36.2666 18.3444L32.0253 14.0997L32.0058 14.1189C30.3409 15.7507 27.5612 17.9087 24.3904 18.0725C25.1856 17.1859 26.9391 14.9319 26.9391 12.2941C26.9391 9.65627 25.1856 7.4022 24.3904 6.51568L24.0068 6.07479L23.6231 6.51568C22.8279 7.4022 21.0744 9.65627 21.0744 12.2941C21.0744 14.9319 22.8279 17.1859 23.6231 18.0725C20.4399 17.9081 17.6509 15.7338 15.9882 14.0997L11.8356 18.2568Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_762_16392'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </Icon>
));

HDX.displayName = 'HDX';

export { HDX };
