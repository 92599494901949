import { forwardRef } from 'react';

import { Icon, IconProps } from '@/component-library/Icon';

const qDOT = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>qDOT</title>
    <g clipPath='url(#clip0_23_30)'>
      <circle cx='12' cy='12' r='11.5' fill='#311CAF' stroke='#0547F2' />
      <g clipPath='url(#clip1_23_30)'>
        <path
          d='M11.9995 8.51691C13.2705 8.51691 14.3007 7.95348 14.3007 7.25846C14.3007 6.56343 13.2705 6 11.9995 6C10.7286 6 9.69836 6.56343 9.69836 7.25846C9.69836 7.95348 10.7286 8.51691 11.9995 8.51691Z'
          fill='#E6007A'
        />
        <path
          d='M11.9995 17.9991C13.2705 17.9991 14.3007 17.4357 14.3007 16.7407C14.3007 16.0457 13.2705 15.4822 11.9995 15.4822C10.7286 15.4822 9.69836 16.0457 9.69836 16.7407C9.69836 17.4357 10.7286 17.9991 11.9995 17.9991Z'
          fill='#E6007A'
        />
        <path
          d='M8.79271 10.2589C9.42816 9.22402 9.42404 8.10317 8.7835 7.75542C8.14296 7.40767 7.10856 7.96472 6.4731 8.99961C5.83765 10.0345 5.84177 11.1554 6.48231 11.5031C7.12286 11.8509 8.15726 11.2938 8.79271 10.2589Z'
          fill='#E6007A'
        />
        <path
          d='M17.525 14.9995C18.1605 13.9646 18.1567 12.8439 17.5166 12.4964C16.8765 12.1489 15.8424 12.7061 15.207 13.741C14.5715 14.7759 14.5753 15.8966 15.2154 16.2441C15.8555 16.5916 16.8896 16.0344 17.525 14.9995Z'
          fill='#E6007A'
        />
        <path
          d='M8.7838 16.2438C9.42434 15.896 9.42847 14.7752 8.79301 13.7403C8.15756 12.7054 7.12316 12.1483 6.48262 12.4961C5.84208 12.8438 5.83795 13.9647 6.47341 14.9996C7.10886 16.0345 8.14326 16.5915 8.7838 16.2438Z'
          fill='#E6007A'
        />
        <path
          d='M17.5172 11.5034C18.1573 11.1559 18.161 10.0352 17.5256 9.00031C16.8901 7.96541 15.8561 7.40818 15.216 7.75569C14.5759 8.1032 14.5721 9.22387 15.2075 10.2588C15.843 11.2937 16.877 11.8509 17.5172 11.5034Z'
          fill='#E6007A'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_23_30'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
      <clipPath id='clip1_23_30'>
        <rect width='12' height='12' fill='white' transform='translate(6 6)' />
      </clipPath>
    </defs>
  </Icon>
));

qDOT.displayName = 'qDOT';

export { qDOT };
