import { forwardRef } from 'react';

import { Icon, IconProps } from '@/component-library/Icon';

const IBTC = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>IBTC</title>
    <g clipPath='url(#clip0_35_20)'>
      <circle cx='12' cy='12' r='11.5' fill='white' stroke='#FF9900' />
      <g clipPath='url(#clip1_35_20)'>
        <path
          d='M18.7522 17.9998H9.2658C9.22061 17.9996 9.17632 17.986 9.1378 17.9605L5.71038 15.6728C5.66418 15.6434 5.6283 15.5983 5.60835 15.5445C5.58841 15.4907 5.58553 15.4313 5.60016 15.3755C5.6148 15.3198 5.64612 15.2709 5.68923 15.2364C5.73233 15.202 5.78479 15.184 5.83838 15.1852H15.3248C15.37 15.1851 15.4143 15.1987 15.4528 15.2245L18.8802 17.5118C18.9266 17.5411 18.9627 17.5862 18.9828 17.6401C19.0028 17.694 19.0058 17.7535 18.9911 17.8094C18.9765 17.8653 18.945 17.9143 18.9018 17.9488C18.8585 17.9833 18.8059 18.0012 18.7522 17.9998ZM9.33485 17.4725H17.8953L15.2557 15.7121H6.69524L9.33485 17.4725ZM18.1618 8.81438H8.67545C8.63026 8.81423 8.58597 8.80064 8.54745 8.7751L5.11963 6.48737C5.07353 6.45803 5.0377 6.41303 5.01774 6.35938C4.99778 6.30573 4.99481 6.24645 5.00928 6.19078C5.02376 6.13511 5.05487 6.08619 5.09777 6.05164C5.14066 6.01709 5.19293 5.99886 5.24642 5.99979H14.7356C14.7808 5.99994 14.8251 6.01353 14.8636 6.03908L18.289 8.32681C18.3352 8.35619 18.3711 8.40131 18.391 8.45509C18.411 8.50888 18.4139 8.5683 18.3992 8.62404C18.3846 8.67979 18.3533 8.72871 18.3102 8.76315C18.2671 8.7976 18.2146 8.81561 18.161 8.81438H18.1618ZM8.7445 8.28752H17.305L14.6654 6.52665H6.10489L8.7445 8.28752Z'
          fill='black'
        />
        <path
          d='M12.1698 11.8605C12.3664 11.8605 12.6261 11.8365 12.7718 11.6763C12.8075 11.6416 12.8359 11.5989 12.855 11.5512C12.8741 11.5035 12.8835 11.452 12.8825 11.4C12.8776 11.0355 12.5998 10.9068 12.3394 10.9076H11.9477L11.9501 11.8431L11.9945 11.8488C12.0527 11.8562 12.1112 11.8601 12.1698 11.8605ZM12.3652 12.163L11.9517 12.1678L11.955 13.2648H12.3737C12.6806 13.2648 13.0085 13.1185 13.0093 12.7143C13.0118 12.6478 13.0017 12.5813 12.9796 12.5191C12.9575 12.4569 12.9239 12.4002 12.8809 12.3525C12.7165 12.1757 12.4448 12.1626 12.3652 12.163Z'
          fill='#FF9900'
        />
        <path
          d='M13.9538 10.0931C13.4827 9.61408 12.8559 9.35513 12.2094 9.37244H12.1835C11.531 9.39641 10.9137 9.69854 10.4665 10.2128C10.0193 10.7271 9.77856 11.4118 9.7968 12.1174C9.81504 12.823 10.0908 13.4921 10.5639 13.9786C11.0369 14.4651 11.6689 14.7294 12.3216 14.714H12.3463C12.8348 14.6965 13.3076 14.5228 13.7049 14.2149C14.1021 13.907 14.406 13.4787 14.578 12.9841C14.7501 12.4895 14.7826 11.9508 14.6715 11.4362C14.5604 10.9217 14.3106 10.4543 13.9538 10.0931ZM13.2157 13.4206C13.063 13.4856 12.9008 13.5206 12.7367 13.5241H12.6867L12.6826 13.5782V14.1068H12.3164V13.5201L11.9558 13.5232V14.1059H11.5867L11.5859 13.5254L10.9657 13.5306V13.2687H11.1959C11.2425 13.2685 11.2871 13.2483 11.32 13.2125C11.3528 13.1767 11.3712 13.1283 11.3711 13.0779L11.3651 11.1001C11.3651 11.0015 11.2924 10.933 11.189 10.933L10.958 10.9155V10.5506H11.5847V9.9495H11.9481V10.5514H12.3116V9.94819H12.675V10.5519H12.742C12.8676 10.5519 13.4971 10.6933 13.4987 11.2442C13.4987 11.8514 13.0279 11.9168 13.0198 11.9352L12.9948 11.9374C13.021 11.9404 13.636 12.0683 13.6316 12.7715C13.6279 13.0762 13.4914 13.294 13.2157 13.4206Z'
          fill='#FF9900'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_35_20'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
      <clipPath id='clip1_35_20'>
        <rect width='14' height='12' fill='white' transform='translate(5 6)' />
      </clipPath>
    </defs>
  </Icon>
));

IBTC.displayName = 'IBTC';

export { IBTC };
