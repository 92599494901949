import { forwardRef } from 'react';

import { Icon, IconProps } from '@/component-library/Icon';

const WBTC = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>WBTC</title>
    <circle cx='12.056' cy='12.056' r='11.493' fill='#fff' />
    <path
      fill='#F7931A'
      fillRule='evenodd'
      d='M14.042 10.338c0 1.328-2.07 1.167-2.728 1.167V9.166c.658 0 2.728-.208 2.728 1.172Zm.541 3.632c0 1.45-2.48 1.285-3.271 1.285v-2.57c.79 0 3.271-.23 3.271 1.29m1.634-4.154c-.132-1.393-1.324-1.853-2.833-1.998V5.914h-1.159v1.871c-.307 0-.62 0-.931.012V5.914h-1.165v1.92H7.767v1.25s.86-.015.848 0a.602.602 0 0 1 .661.513v5.26a.412.412 0 0 1-.437.387c.015.014-.848 0-.848 0l-.23 1.395h2.353v1.957h1.166v-1.928h.93v1.915h1.166v-1.93c1.969-.115 3.337-.606 3.514-2.453.14-1.488-.558-2.15-1.673-2.416.678-.346 1.104-.954 1.002-1.967'
      clipRule='evenodd'
    />
    <path
      fill='#000'
      d='M5.675 4.982a9.378 9.378 0 0 1 8.87-2.062l.133.038a7.331 7.331 0 0 1 .479.158l.091.033c.126.046.251.096.374.148l.015.006c.982.422 1.894.99 2.705 1.685l.514-.555c-.07-.069-.14-.132-.211-.197a10.176 10.176 0 0 0-6.381-2.481h-.403a10.62 10.62 0 0 0-2.264.276l-.151.037-.127.033c-.74.198-1.455.48-2.131.839a8.104 8.104 0 0 0-1.999 1.513l.486.53ZM5.025 5.628l-.564-.494c-3.825 4.009-3.268 10.532 0 13.64l.575-.449a9.391 9.391 0 0 1-.01-12.697h-.001ZM19.78 5.384a6.66 6.66 0 0 0-.272-.27l-.494.541c2.9 3.295 3.512 8.524.091 12.55l.481.581a10.214 10.214 0 0 0 .198-13.402h-.005ZM18.38 18.975c-.334.25-.666.479-.998.69a9.363 9.363 0 0 1-4.061 1.62c-.748.12-1.51.138-2.262.054a9.37 9.37 0 0 1-5.147-2.173L5.682 19l-.53.476c1.855 1.832 4.4 2.714 6.903 2.713h.192a10.173 10.173 0 0 0 6.34-2.432c.08-.076.162-.15.239-.23l-.447-.55Z'
    />
    <path
      fill='#fff'
      d='M11.995.805a11.194 11.194 0 1 1-11.19 11.19A11.207 11.207 0 0 1 11.995.805Zm0-.805a12 12 0 1 0 .01 24 12 12 0 0 0-.01-24Z'
    />
  </Icon>
));

WBTC.displayName = 'WBTC';

export { WBTC };
