import {
  AUSD,
  BNC,
  BTC,
  DAI,
  DOT,
  ETH,
  GLMR,
  HDX,
  IBTC,
  INTR,
  KAR,
  KBTC,
  KINT,
  KSM,
  LDOT,
  LKSM,
  LSKSM,
  MOVR,
  qDOT,
  qIBTC,
  qINTR,
  qKBTC,
  qKINT,
  qKSM,
  qMOVR,
  qUSDT,
  SKSM,
  TBTC,
  USDC,
  USDT,
  VDOT,
  VKSM,
  WBNB,
  WBTC,
  WETH
} from './icons';
import { CoinComponent } from './types';

export const coins: Record<string, CoinComponent> = {
  AUSD,
  BTC,
  BNC,
  DAI,
  DOT,
  ETH,
  GLMR,
  HDX,
  IBTC,
  INTR,
  KAR,
  KBTC,
  KINT,
  KSM,
  LDOT,
  LKSM,
  LSKSM,
  MOVR,
  qDOT,
  qIBTC,
  qINTR,
  qKBTC,
  qKINT,
  qKSM,
  qMOVR,
  qUSDT,
  SKSM,
  TBTC,
  USDC,
  USDT,
  VDOT,
  VKSM,
  WBNB,
  WBTC,
  WETH
};
