import { forwardRef } from 'react';

import { Icon, IconProps } from '@/component-library/Icon';

const AUSD = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>AUSD</title>
    <g clipPath='url(#clip0_23_46)'>
      <circle cx='12' cy='12' r='11.5' fill='white' stroke='#E40C5B' />
      <g clipPath='url(#clip1_23_46)'>
        <path
          d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47716 17.5228 2 12 2C6.47716 2 2 6.47716 2 12C2 17.5228 6.47716 22 12 22Z'
          fill='#E40C5B'
        />
        <path
          d='M12.0001 20.8523C16.8891 20.8523 20.8525 16.889 20.8525 12C20.8525 7.11099 16.8891 3.14769 12.0001 3.14769C7.11114 3.14769 3.14783 7.11099 3.14783 12C3.14783 16.889 7.11114 20.8523 12.0001 20.8523Z'
          stroke='white'
        />
        <path
          d='M10.1684 15.0336V17.3409C10.1684 17.6024 10.1129 17.8292 10.0019 18.0213C9.89093 18.2117 9.73171 18.357 9.52425 18.457C9.31679 18.557 9.07151 18.6072 8.78843 18.6072C8.36062 18.6072 8.02367 18.4958 7.7776 18.273C7.53151 18.0503 7.40609 17.7453 7.40125 17.3579V15.0336H8.25289V17.3748C8.26253 17.7606 8.44104 17.9534 8.78843 17.9534C8.96374 17.9534 9.09643 17.905 9.18651 17.8083C9.27656 17.7114 9.32159 17.554 9.32159 17.3361V15.0336H10.1684Z'
          fill='white'
        />
        <path
          d='M12.5109 17.6218C12.5109 17.4975 12.4667 17.4006 12.3782 17.3312C12.2913 17.2618 12.1377 17.19 11.9174 17.1157C11.6971 17.0415 11.5169 16.9697 11.377 16.9003C10.9219 16.6759 10.6943 16.3677 10.6943 15.9754C10.6943 15.7801 10.7506 15.6082 10.8631 15.4597C10.9774 15.3096 11.1382 15.1934 11.3456 15.1111C11.5531 15.0272 11.7863 14.9852 12.0453 14.9852C12.2978 14.9852 12.5238 15.0304 12.7232 15.1208C12.9242 15.2112 13.0802 15.3403 13.1912 15.5082C13.3022 15.6744 13.3577 15.8649 13.3577 16.0795H12.5133C12.5133 15.9359 12.4691 15.8245 12.3806 15.7454C12.2938 15.6663 12.1756 15.6268 12.026 15.6268C11.8748 15.6268 11.755 15.6607 11.6665 15.7285C11.5797 15.7947 11.5362 15.8794 11.5362 15.9827C11.5362 16.0731 11.5845 16.1554 11.681 16.2297C11.7775 16.3023 11.9472 16.3781 12.19 16.4572C12.4329 16.5347 12.6323 16.6186 12.7883 16.709C13.1679 16.9285 13.3577 17.2312 13.3577 17.6169C13.3577 17.9252 13.2419 18.1673 13.0102 18.3433C12.7787 18.5192 12.461 18.6072 12.0573 18.6072C11.7727 18.6072 11.5145 18.5564 11.2829 18.4547C11.0529 18.3514 10.8792 18.2109 10.7619 18.0334C10.646 17.8542 10.5881 17.6484 10.5881 17.416H11.4373C11.4373 17.6048 11.4856 17.7445 11.5821 17.8348C11.6802 17.9236 11.8386 17.968 12.0573 17.968C12.1973 17.968 12.3074 17.9381 12.3879 17.8784C12.4699 17.817 12.5109 17.7316 12.5109 17.6218Z'
          fill='white'
        />
        <path
          d='M13.7919 18.5588V15.0336H14.9234C15.2338 15.0336 15.5128 15.1046 15.7605 15.2467C16.0082 15.3871 16.2012 15.5865 16.3395 15.8447C16.4794 16.1013 16.5502 16.3895 16.5518 16.709V16.8713C16.5518 17.194 16.4835 17.4838 16.3467 17.7405C16.2116 17.9955 16.0203 18.1956 15.7726 18.3408C15.5265 18.4845 15.2515 18.557 14.9475 18.5588H13.7919ZM14.6387 15.6897V17.905H14.933C15.1759 17.905 15.3624 17.8186 15.4927 17.6459C15.623 17.4717 15.6882 17.2134 15.6882 16.8713V16.7187C15.6882 16.3781 15.623 16.1215 15.4927 15.9488C15.3624 15.7761 15.1727 15.6897 14.9234 15.6897H14.6387Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.9927 10.9835C11.6451 10.9831 11.2981 11.0122 10.9554 11.0705L11.4718 10.1739C11.6459 10.1621 11.82 10.1562 11.9927 10.1562C12.3989 10.1565 12.8045 10.1891 13.2055 10.2537L11.2066 6.77419L7.8771 12.5678L7.40125 11.7396L11.1989 5.12871L11.2066 5.1423L11.2142 5.12916L15.7976 13.1072H14.846L13.7748 11.2428C13.1967 11.0687 12.5962 10.9812 11.9927 10.9831V10.9835ZM16.0921 12.5814L11.6549 4.85718H12.6066L16.568 11.7532L16.0921 12.5814ZM11.6883 8.66852L9.86612 11.8416C10.5183 11.6439 11.2855 11.5556 12.0094 11.5556C12.0681 11.5556 12.1267 11.5556 12.1853 11.5578C12.5964 11.5676 13.006 11.6103 13.4103 11.6856L14.0183 12.7441C13.3749 12.5059 12.695 12.3832 12.0094 12.3815C11.0105 12.3779 10.0264 12.6247 9.14628 13.0995L9.15984 13.0755L9.14132 13.1072H8.18964L11.2156 7.84032L11.6883 8.66852Z'
          fill='white'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_23_46'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
      <clipPath id='clip1_23_46'>
        <rect width='20' height='20' fill='white' transform='translate(2 2)' />
      </clipPath>
    </defs>
  </Icon>
));

AUSD.displayName = 'AUSD';

export { AUSD };
