import { forwardRef } from 'react';

import { Icon, IconProps } from '@/component-library/Icon';

const DAI = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>DAI</title>
    <g clipPath='url(#dai-a)'>
      <path
        fill='#F5AC37'
        d='M12 0c6.628 0 12 5.373 12 12 0 6.628-5.372 12-12 12-6.627 0-12-5.372-12-12C0 5.373 5.373 0 12 0Z'
      />
      <path
        fill='#FEFEFD'
        d='M12.442 12.847h4.56c.097 0 .143 0 .15-.127a8.73 8.73 0 0 0 0-1.395c0-.09-.045-.127-.142-.127H7.935c-.112 0-.143.037-.143.142v1.335c0 .172 0 .172.18.172h4.47Zm4.201-3.21a.147.147 0 0 0 0-.104 2.975 2.975 0 0 0-.271-.473 3.83 3.83 0 0 0-.555-.697 1.84 1.84 0 0 0-.345-.338 5.391 5.391 0 0 0-2.25-1.14 5.594 5.594 0 0 0-1.275-.135H7.919c-.112 0-.127.045-.127.143v2.662c0 .112 0 .142.142.142h8.655s.075-.015.09-.06h-.037.001Zm0 4.77a1.743 1.743 0 0 0-.383 0H7.942c-.112 0-.15 0-.15.15v2.603c0 .12 0 .15.15.15h3.84c.184.015.368.002.547-.037a5.72 5.72 0 0 0 1.628-.36c.19-.066.374-.152.547-.255h.053a4.872 4.872 0 0 0 2.092-2.107s.053-.113-.006-.143ZM6.285 18.66v-4.147c0-.098 0-.113-.12-.113H4.538c-.09 0-.128 0-.128-.12v-1.425h1.74c.097 0 .135 0 .135-.127v-1.41c0-.09 0-.112-.12-.112H4.538c-.09 0-.128 0-.128-.12v-1.32c0-.083 0-.105.12-.105h1.613c.112 0 .142 0 .142-.142V5.476c0-.12 0-.15.15-.15h5.625c.408.016.814.061 1.215.135a7.33 7.33 0 0 1 2.347.87c.482.284.925.626 1.32 1.02.297.308.565.642.803.997.236.36.432.746.585 1.148.02.105.12.175.225.158h1.342c.172 0 .172 0 .18.165v1.23c0 .12-.045.15-.165.15h-1.036c-.104 0-.135 0-.127.135.041.457.041.915 0 1.372 0 .128 0 .143.143.143h1.184c.053.067 0 .135 0 .203.008.087.008.175 0 .262v.908c0 .127-.037.165-.15.165H18.51a.188.188 0 0 0-.218.142 5.998 5.998 0 0 1-1.575 2.295c-.255.23-.523.446-.803.645-.3.173-.592.353-.9.495a8.104 8.104 0 0 1-1.77.563 9.227 9.227 0 0 1-1.755.142H6.283v-.008l.002-.001Z'
      />
    </g>
    <defs>
      <clipPath id='dai-a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </Icon>
));

DAI.displayName = 'DAI';

export { DAI };
