import { forwardRef } from 'react';

import { Icon, IconProps } from '@/component-library/Icon';

const qMOVR = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>qMOVR</title>
    <g clipPath='url(#clip0_1_285)'>
      <circle cx='12' cy='12' r='11.5' fill='#311CAF' stroke='#0547F2' />
      <mask
        id='mask0_1_285'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='4'
        width='16'
        height='16'
      >
        <path d='M19.9193 4H4V20H19.9193V4Z' fill='white' />
      </mask>
      <g mask='url(#mask0_1_285)'>
        <path
          d='M11.8535 4.27946C8.68029 4.27946 6.10985 6.86507 6.10773 10.0544C6.10773 10.4192 6.14169 10.784 6.20961 11.1424L6.95251 10.6325C8.2579 9.73227 10.3741 9.73227 11.6774 10.6325L12.4564 11.1701L12.8788 11.4624C14.0547 12.2731 15.9607 12.2731 17.1345 11.4624L17.4805 11.2256C18.1173 8.10027 16.1136 5.04746 13.004 4.40533C12.6262 4.3264 12.242 4.288 11.8557 4.288L11.8535 4.27946Z'
          fill='#F2B705'
        />
      </g>
      <mask
        id='mask1_1_285'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='4'
        width='16'
        height='16'
      >
        <path d='M19.9193 4H4V20H19.9193V4Z' fill='white' />
      </mask>
      <g mask='url(#mask1_1_285)'>
        <path
          d='M15.082 13.5232C14.2605 13.5381 13.454 13.3077 12.7662 12.8619L11.567 12.0363C10.2361 11.2149 8.55718 11.2149 7.2242 12.0363L6.44945 12.5696C6.29663 12.6763 6.08649 12.6357 5.98036 12.4821C5.87424 12.3285 5.91456 12.1173 6.06739 12.0107L6.84213 11.4773C8.40435 10.5024 10.3826 10.5024 11.9448 11.4773L13.1441 12.3029C14.3306 13.0347 15.827 13.0347 17.0135 12.3029L17.4678 11.9893C17.6206 11.8827 17.8307 11.9232 17.9369 12.0768C18.043 12.2304 18.0027 12.4416 17.8498 12.5483L17.3956 12.8619C16.7079 13.3056 15.9013 13.536 15.082 13.5232Z'
          fill='#53CBC8'
        />
      </g>
      <mask
        id='mask2_1_285'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='4'
        width='16'
        height='16'
      >
        <path d='M19.9193 4H4V20H19.9193V4Z' fill='white' />
      </mask>
      <g mask='url(#mask2_1_285)'>
        <path
          d='M9.37015 15.9488V12.6848C9.36803 12.6165 9.37015 12.5483 9.37864 12.48C9.39987 12.2944 9.55694 12.1536 9.74372 12.1536C9.92414 12.16 10.0706 12.2987 10.0876 12.48C10.1024 12.5931 10.1046 12.7083 10.1003 12.8213V19.328C10.1088 19.5392 9.94537 19.7141 9.73523 19.7227C9.73311 19.7227 9.73099 19.7227 9.72887 19.7227C9.52298 19.712 9.36591 19.5349 9.37652 19.328C9.37652 19.3216 9.37652 19.3152 9.37864 19.3109V17.1392L9.37015 15.9488Z'
          fill='#53CBC8'
        />
      </g>
      <mask
        id='mask3_1_285'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='4'
        width='16'
        height='16'
      >
        <path d='M19.9193 4H4V20H19.9193V4Z' fill='white' />
      </mask>
      <g mask='url(#mask3_1_285)'>
        <path
          d='M8.68875 14.3061V15.9275C8.68875 16.2048 8.53805 16.384 8.33216 16.384C8.12839 16.384 7.96495 16.1899 7.96283 15.9275V12.6827C7.96283 12.3989 8.12839 12.2069 8.34277 12.2261C8.55715 12.2453 8.68451 12.4075 8.68663 12.6933C8.68875 13.2245 8.68875 13.7643 8.68875 14.3061Z'
          fill='#53CBC8'
        />
      </g>
      <mask
        id='mask4_1_285'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='4'
        width='16'
        height='16'
      >
        <path d='M19.9193 4H4V20H19.9193V4Z' fill='white' />
      </mask>
      <g mask='url(#mask4_1_285)'>
        <path
          d='M10.7838 14.304V13.1669C10.7838 12.9024 10.9238 12.7317 11.1319 12.7317C11.3462 12.7424 11.5139 12.9259 11.5033 13.1413C11.5033 13.1456 11.5033 13.152 11.5033 13.1563C11.5097 13.9307 11.5097 14.7051 11.5033 15.4816C11.5033 15.7461 11.3526 15.9061 11.134 15.9019C10.9175 15.8997 10.7859 15.7376 10.7838 15.4667V14.304Z'
          fill='#53CBC8'
        />
      </g>
      <mask
        id='mask5_1_285'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='4'
        width='16'
        height='16'
      >
        <path d='M19.9193 4H4V20H19.9193V4Z' fill='white' />
      </mask>
      <g mask='url(#mask5_1_285)'>
        <path
          d='M6.55768 14.5387V13.3995C6.55768 13.1349 6.69777 12.9643 6.90578 12.9643C7.12016 12.9749 7.28785 13.1584 7.27723 13.3739C7.27723 13.3781 7.27723 13.3845 7.27723 13.3888C7.2836 14.1653 7.2836 14.9397 7.27723 15.7163C7.27723 15.9808 7.12653 16.1387 6.91003 16.1365C6.69565 16.1344 6.5598 15.9723 6.55768 15.7013V14.5387Z'
          fill='#53CBC8'
        />
      </g>
      <mask
        id='mask6_1_285'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='4'
        width='16'
        height='16'
      >
        <path d='M19.9193 4H4V20H19.9193V4Z' fill='white' />
      </mask>
      <g mask='url(#mask6_1_285)'>
        <path
          d='M12.5434 13.5701C12.7408 13.5701 12.9 13.7301 12.9 13.9285V14.5472C12.9 14.7456 12.7408 14.9056 12.5434 14.9056C12.346 14.9056 12.1868 14.7456 12.1868 14.5472V13.9285C12.1889 13.7323 12.3481 13.5701 12.5434 13.5701Z'
          fill='#53CBC8'
        />
      </g>
      <mask
        id='mask7_1_285'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='4'
        width='16'
        height='16'
      >
        <path d='M19.9193 4H4V20H19.9193V4Z' fill='white' />
      </mask>
      <g mask='url(#mask7_1_285)'>
        <path
          d='M13.9549 14.1141C14.1523 14.1141 14.3115 14.2741 14.3115 14.4725V17.9477C14.3115 18.1461 14.1523 18.3061 13.9549 18.3061C13.7575 18.3061 13.5983 18.1461 13.5983 17.9477V14.4725C13.5983 14.2741 13.7575 14.1141 13.9549 14.1141Z'
          fill='#53CBC8'
        />
      </g>
      <mask
        id='mask8_1_285'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='4'
        width='16'
        height='16'
      >
        <path d='M19.9193 4H4V20H19.9193V4Z' fill='white' />
      </mask>
      <g mask='url(#mask8_1_285)'>
        <path
          d='M15.3579 14.2485C15.5553 14.2485 15.7145 14.4085 15.7145 14.6069V17.5595C15.7145 17.7579 15.5553 17.9179 15.3579 17.9179C15.1605 17.9179 15.0013 17.7579 15.0013 17.5595V14.6069C15.0013 14.4085 15.1605 14.2485 15.3579 14.2485Z'
          fill='#53CBC8'
        />
      </g>
      <mask
        id='mask9_1_285'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='4'
        width='16'
        height='16'
      >
        <path d='M19.9193 4H4V20H19.9193V4Z' fill='white' />
      </mask>
      <g mask='url(#mask9_1_285)'>
        <path
          d='M16.7779 13.9115C16.9753 13.9115 17.1345 14.0715 17.1345 14.2699V15.5435C17.1345 15.7419 16.9753 15.9019 16.7779 15.9019C16.5805 15.9019 16.4213 15.7419 16.4213 15.5435V14.2699C16.4213 14.0715 16.5826 13.9115 16.7779 13.9115Z'
          fill='#53CBC8'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1_285'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </Icon>
));

qMOVR.displayName = 'qMOVR';

export { qMOVR };
